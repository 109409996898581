import React, { useState } from "react"
import { LocationProvider } from "@reach/router"

import { allLangs } from "../utils/configClient"

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false)

  const [currentLang, setCurrentLang] = useState(allLangs.default)

  const [searchVisible, setSearchVisible] = useState(false)
  const [lightBoxHonor, setLightBoxHonor] = useState(null)
  const [lightBoxImage, setLightBoxImage] = useState(null)
  const [lightBoxVisible, setLightBoxVisible] = useState(false)
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false)
  const [header, setHeader] = useState({
    theme: "light",
    bgClass: "",
    isFluid: false,
  })
  const [footer, setFooter] = useState({
    theme: "dark",
  })

  const toggleTheme = () => {
    setThemeDark(!themeDark)
  }

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible)
  }

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas)
  }
  const closeOffCanvas = () => {
    setVisibleOffCanvas(false)
  }

  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
  }
  const closeSearch = () => {
    setSearchVisible(false)
  }

  const toggleLightBox = () => {
    setLightBoxVisible(!lightBoxVisible)
  }

  const [activeAboutTab, setActiveAboutTab] = useState(null)

  return (
    <LocationProvider>
      <GlobalContext.Provider
        value={{
          currentLang,
          setCurrentLang,
          themeDark,
          toggleTheme,
          videoModalVisible,
          toggleVideoModal,
          visibleOffCanvas,
          toggleOffCanvas,
          closeOffCanvas,
          header,
          setHeader,
          footer,
          setFooter,
          searchVisible,
          toggleSearch,
          closeSearch,
          activeAboutTab,
          setActiveAboutTab,
          lightBoxHonor,
          setLightBoxHonor,
          lightBoxImage,
          setLightBoxImage,
          lightBoxVisible,
          toggleLightBox,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </LocationProvider>
  )
}

export default GlobalContext
export { GlobalProvider }
