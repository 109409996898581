import React from "react"
import styled from "styled-components"
import { color, space, typography, shadow } from "styled-system"
import { device } from "../../utils"

const SectionTitle = styled.h2`
  font-family: Roboto;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.4;
  letter-spacing: 0.1px;
  margin-bottom: 0;

  @media ${device.md} {
    font-size: 36px;
    letter-spacing: 0.125px;
  }

  @media ${device.lg} {
    font-size: 40px;
    letter-spacing: 0.2px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const HeroTitle = styled(SectionTitle)`
  font-size: 36px;
  line-height: 1.4;
  letter-spacing: 0.1px;

  @media ${device.md} {
    font-size: 48px;
    letter-spacing: 0.125px;
  }

  @media ${device.xl} {
    font-size: 56px;
    letter-spacing: 0.2px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const CardTitle = styled.h4`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
  @media ${device.lg} {
    font-size: 20px;
    line-height: 25px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Title = ({ variant, color = "heading", ...rest }) => {
  let TitleStyled = SectionTitle

  switch (variant) {
    case "card":
      TitleStyled = CardTitle
      break
    case "hero":
      TitleStyled = HeroTitle
      break
    default:
      TitleStyled = SectionTitle
  }

  return <TitleStyled color={color} {...rest} />
}

export default Title
