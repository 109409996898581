import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Marquee from "react-marquee-slider"
import times from "lodash/times"

import { useLocation } from "@reach/router"

import GlobalContext from "../../contexts/GlobalContext"

import { Box } from "../Core"
import { slugNews } from "../../utils/slugs"

const HeroSlider = ({ allTickersNodes }) => {
  const location = useLocation()
  const { pathname } = location

  const gContext = useContext(GlobalContext)

  const [tickerConfig, setTickerConfig] = useState(null)
  const [tickerItems, setTickerItems] = useState(null)

  useEffect(() => {
    const catsTickersNodes = allTickersNodes.filter(
      item => pathname.indexOf(item?.data.ref_category.uid) !== -1
    )

    const generalTickersNodes = allTickersNodes.filter(
      item => item?.data.page_type === "General"
    )

    const currentConfig =
      catsTickersNodes.length > 0
        ? catsTickersNodes[0]?.data
        : generalTickersNodes[0]?.data

    setTickerConfig(currentConfig)
    setTickerItems(currentConfig?.ticker_items || null)
  }, [pathname, tickerConfig, tickerItems])

  return tickerConfig ? (
    <Box className="position-relative h-100">
      <Box className="d-flex align-items-center h-100">
        <Box
          bg={tickerConfig.title_bg}
          className="h-100 align-items-center d-none d-sm-flex"
          px={3}
        >
          <h5
            className="mb-0"
            css={`
              color: ${tickerConfig.title_color} !important;
            `}
          >
            {tickerConfig?.title?.text === ""
              ? "News"
              : tickerConfig?.title?.text}
          </h5>
        </Box>
        <Box
          className="w-100 h-100"
          css={`
            background-color: ${tickerConfig.content_bg} !important;
            .ticker {
              height: 100% !important;
            }
            .ticker__element {
              height: 100% !important;
            }
          `}
        >
          {tickerItems && tickerItems[0].ticker_item.document ? (
            <Marquee velocity={20}>
              {times(5, Number).map(id => (
                <div
                  className="d-flex align-items-center"
                  key={id}
                  css={`
                    height: 60px;
                  `}
                >
                  {tickerItems.map(
                    ({ ticker_item_title, ticker_item: { document } }) =>
                      document && (
                        <div key={document.uid} className="mr-4">
                          <Link
                            to={slugNews(document.uid, gContext.currentLang)}
                          >
                            <span
                              className="mr-2"
                              css={`
                                color: ${tickerConfig.text_color} !important;
                              `}
                            >
                              {ticker_item_title?.text
                                ? ticker_item_title?.text
                                : document?.data.title?.text}
                            </span>
                            <small
                              css={`
                                color: ${tickerConfig.sub_text_color} !important;
                              `}
                            >
                              - Read News {document?.data.date}
                            </small>
                          </Link>
                        </div>
                      )
                  )}
                </div>
              ))}
            </Marquee>
          ) : (
            <div
              className="d-flex align-items-center p-3 h-100"
              css={`
                color: ${tickerConfig.text_color} !important;
              `}
            >
              No news to show here!
            </div>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}

export default HeroSlider
