import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import { animateScroll as scroll } from "react-scroll"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"
import { Container, Row, Col } from "react-bootstrap"
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"

import { Title, Box } from "../Core"
import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"

import {
  slugCat,
  slugHome,
  slugAbout,
  slugHonors,
  slugApplications,
  slugCareer,
  slugDisclaimer,
} from "../../utils/slugs"
import Logo from "../Logo"

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
`

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 1.5;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
    margin-bottom: 1rem;
  }
`

const CopyRightArea = styled.div`
  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`

const Footer = ({ isDark = false }) => {
  const {
    allPrismicPageSection,
    allPrismicCategory,
    allPrismicCommon,
  } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            footer_menu_about_title {
              text
            }
            footer_menu_news_title {
              text
            }
            footer_menu_product_title {
              text
            }
            footer_menu_support_title {
              text
            }
            footer_link_news_text {
              text
            }
            footer_link_honor_text {
              text
            }
            footer_link_contact_text {
              text
            }
            footer_link_sample_text {
              text
            }
            footer_link_applications_text {
              text
            }
            footer_link_career_text {
              text
            }
            footer_link_disclaimer_text {
              text
            }
            footer_copyright_text {
              text
            }
          }
        }
      }
      allPrismicPageSection(sort: { order: ASC, fields: data___order }) {
        nodes {
          lang
          uid
          data {
            page_type
            title {
              text
            }
          }
        }
      }
      allPrismicCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          lang
          uid
          data {
            title_footer {
              text
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const {
    footer_menu_about_title,
    footer_menu_news_title,
    footer_menu_product_title,
    footer_menu_support_title,
    footer_link_news_text,
    footer_link_honor_text,
    footer_link_contact_text,
    footer_link_sample_text,
    footer_link_applications_text,
    footer_link_career_text,
    footer_link_disclaimer_text,
    footer_copyright_text,
  } = prismicCommon?.data

  const catsByLang = itemsByLang(allPrismicCategory.nodes, gContext.currentLang)

  const allPrismicPageSectionNodes = itemsByLang(
    allPrismicPageSection.nodes,
    gContext.currentLang
  )

  const aboutSections = allPrismicPageSectionNodes.filter(
    section => section.data.page_type === "About"
  )

  const { pathname } = useLocation()

  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
              border-top: ${({ theme }) =>
                `1px solid ${theme.colors.lightShade}`};
              border-bottom: ${({ theme }) =>
                `1px solid ${theme.colors.lightShade}`};
            `}
          >
            <Row className="justify-content-center">
              <Col lg="2" md="4">
                <Link to={slugHome(gContext.currentLang)}>
                  <Logo white={isDark} />
                </Link>
              </Col>
              <Col lg="10" md="8" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        {footer_menu_about_title?.text || "About"}
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        {aboutSections.length > 0 &&
                          aboutSections.map(sec => (
                            <li key={sec.uid}>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault()

                                  if (
                                    pathname === slugAbout(gContext.currentLang)
                                  ) {
                                    gContext.setActiveAboutTab(sec.uid)
                                    scroll.scrollToTop({ delay: 0 })
                                  } else {
                                    gContext.setActiveAboutTab(sec.uid)
                                    scroll.scrollToTop({ delay: 0 })
                                    // setTimeout(() => {
                                    //   gContext.setActiveAboutTab(sec.uid)
                                    // }, 50)

                                    setTimeout(() => {
                                      navigate(slugAbout(gContext.currentLang))
                                    }, 100)
                                  }
                                }}
                              >
                                {sec.data.title.text}
                              </a>
                            </li>
                          ))}
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        {footer_menu_news_title?.text || "News"}
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <AnchorLink
                            to={`${slugHome(gContext.currentLang)}#news`}
                            title={footer_link_news_text?.text || "News"}
                            className=""
                            stripHash
                          />
                        </li>
                        <li>
                          <Link
                            to={slugHonors(gContext.currentLang)}
                            className=""
                          >
                            {footer_link_honor_text?.text || "Company Honor"}
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        {footer_menu_product_title?.text || "Product"}
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        {catsByLang.map(({ data, uid }, i) => (
                          <li key={uid}>
                            <Link to={slugCat(uid, gContext.currentLang)}>
                              {data.title_footer.text}
                            </Link>
                          </li>
                        ))}
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="3">
                    <div className="mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        {footer_menu_support_title?.text || "Support"}
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <AnchorLink
                            to={`${pathname}#contact`}
                            title={footer_link_contact_text?.text || "Contact"}
                            className=""
                            stripHash
                          />
                        </li>
                        <li>
                          <AnchorLink
                            to={`${pathname}#support`}
                            title={
                              footer_link_sample_text?.text || "Sample Request"
                            }
                            className=""
                            stripHash
                          />
                        </li>
                        <li>
                          <Link to={slugApplications(gContext.currentLang)}>
                            {footer_link_applications_text?.text ||
                              "Applications"}
                          </Link>
                        </li>
                        <li>
                          <Link to={slugCareer(gContext.currentLang)}>
                            {footer_link_career_text?.text || "Career"}
                          </Link>
                        </li>
                        <li>
                          <Link to={slugDisclaimer(gContext.currentLang)}>
                            {footer_link_disclaimer_text?.text || "Desclaimer"}
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>
                  &copy;{" "}
                  {footer_copyright_text?.text ||
                    `2022 Emeigroup, All Rights Reserved`}
                </p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li>
                    <a href="/#" target="_blank">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="/#" target="_blank">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="/#" target="_blank">
                      <FaLinkedin />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  )
}

export default Footer
