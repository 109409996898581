import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { Modal, Container, Button } from "react-bootstrap"

import GlobalContext from "../../contexts/GlobalContext"
import { Box, Title } from "../../components/Core"
import { theme } from "../../utils"
import { slugHonor } from "../../utils/slugs"

const ModalStyled = styled(Modal)`
  &.modal {
    padding-left: 0 !important;
  }
  .modal-dialog {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
  }
  .modal-content {
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 0;
    padding-top: 15px;
    @media only screen and (min-width: ${theme.breakpoints.lg}px) {
      padding-top: 30px;
    }
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(7px);
      z-index: -1;
    }
  }
`

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 2rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: ${theme.dark};
  @media only screen and (min-width: ${theme.breakpoints.md}px) {
    right: 3rem;
  }
`

const CloseButton = props => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: #ffffff;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="#ffffff"
      ></path>
    </svg>
  </CloseWrapper>
)

const Search = props => {
  const gContext = useContext(GlobalContext)

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.lightBoxVisible}
      onHide={gContext.toggleLightBox}
    >
      <Modal.Body className="text-center position-relative">
        <CloseButton onClick={gContext.toggleLightBox} />
        <Container>
          <div
            className="mt-4 mx-auto d-flex flex-column justify-content-center"
            css={`
              width: 700px;
              max-width: 95%;
            `}
          >
            {gContext.lightBoxImage && (
              <Img
                className="w-100"
                fluid={gContext.lightBoxImage.fluid}
                alt={""}
              />
            )}
          </div>
          {gContext.lightBoxHonor && (
            <Box pt={4}>
              <Title variant="card" color="light" mb="3">
                {gContext.lightBoxHonor.data.title.text}
              </Title>
              <Link
                to={slugHonor(gContext.lightBoxHonor.uid, gContext.currentLang)}
                onClick={gContext.toggleLightBox}
              >
                <Button size="sm">See Other Certificates</Button>
              </Link>
            </Box>
          )}
        </Container>
      </Modal.Body>
    </ModalStyled>
  )
}

export default Search
