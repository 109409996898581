// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-cn-js": () => import("./../../../src/templates/about.cn.js" /* webpackChunkName: "component---src-templates-about-cn-js" */),
  "component---src-templates-about-hk-js": () => import("./../../../src/templates/about.hk.js" /* webpackChunkName: "component---src-templates-about-hk-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-applications-cn-js": () => import("./../../../src/templates/applications.cn.js" /* webpackChunkName: "component---src-templates-applications-cn-js" */),
  "component---src-templates-applications-hk-js": () => import("./../../../src/templates/applications.hk.js" /* webpackChunkName: "component---src-templates-applications-hk-js" */),
  "component---src-templates-applications-js": () => import("./../../../src/templates/applications.js" /* webpackChunkName: "component---src-templates-applications-js" */),
  "component---src-templates-career-cn-js": () => import("./../../../src/templates/career.cn.js" /* webpackChunkName: "component---src-templates-career-cn-js" */),
  "component---src-templates-career-hk-js": () => import("./../../../src/templates/career.hk.js" /* webpackChunkName: "component---src-templates-career-hk-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-category-products-js": () => import("./../../../src/templates/category-products.js" /* webpackChunkName: "component---src-templates-category-products-js" */),
  "component---src-templates-disclaimer-cn-js": () => import("./../../../src/templates/disclaimer.cn.js" /* webpackChunkName: "component---src-templates-disclaimer-cn-js" */),
  "component---src-templates-disclaimer-hk-js": () => import("./../../../src/templates/disclaimer.hk.js" /* webpackChunkName: "component---src-templates-disclaimer-hk-js" */),
  "component---src-templates-disclaimer-js": () => import("./../../../src/templates/disclaimer.js" /* webpackChunkName: "component---src-templates-disclaimer-js" */),
  "component---src-templates-honor-js": () => import("./../../../src/templates/honor.js" /* webpackChunkName: "component---src-templates-honor-js" */),
  "component---src-templates-honors-cn-js": () => import("./../../../src/templates/honors.cn.js" /* webpackChunkName: "component---src-templates-honors-cn-js" */),
  "component---src-templates-honors-hk-js": () => import("./../../../src/templates/honors.hk.js" /* webpackChunkName: "component---src-templates-honors-hk-js" */),
  "component---src-templates-honors-js": () => import("./../../../src/templates/honors.js" /* webpackChunkName: "component---src-templates-honors-js" */),
  "component---src-templates-index-cn-js": () => import("./../../../src/templates/index.cn.js" /* webpackChunkName: "component---src-templates-index-cn-js" */),
  "component---src-templates-index-hk-js": () => import("./../../../src/templates/index.hk.js" /* webpackChunkName: "component---src-templates-index-hk-js" */),
  "component---src-templates-map-js": () => import("./../../../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-product-database-cn-js": () => import("./../../../src/templates/product-database.cn.js" /* webpackChunkName: "component---src-templates-product-database-cn-js" */),
  "component---src-templates-product-database-hk-js": () => import("./../../../src/templates/product-database.hk.js" /* webpackChunkName: "component---src-templates-product-database-hk-js" */),
  "component---src-templates-product-database-js": () => import("./../../../src/templates/product-database.js" /* webpackChunkName: "component---src-templates-product-database-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/sub-category.js" /* webpackChunkName: "component---src-templates-sub-category-js" */),
  "component---src-templates-technical-overview-cn-js": () => import("./../../../src/templates/technical-overview.cn.js" /* webpackChunkName: "component---src-templates-technical-overview-cn-js" */),
  "component---src-templates-technical-overview-hk-js": () => import("./../../../src/templates/technical-overview.hk.js" /* webpackChunkName: "component---src-templates-technical-overview-hk-js" */),
  "component---src-templates-technical-overview-js": () => import("./../../../src/templates/technical-overview.js" /* webpackChunkName: "component---src-templates-technical-overview-js" */)
}

