import { rgba } from "polished"
import { breakpoints } from "./breakpoints"

const defaultColors = {
  white: "#ffffff",
  dark: "#2f4858",
  black: "#000000",
}

const colors = {
  primary: "#E52B38", //main color cta buttons
  secondary: "#b94284", // used for :hover in some place...
  active: "#e5842b", //Active color for links and so on
  bg: defaultColors.white,
  heading: defaultColors.dark,
  text: "#3f527d",
  light: "#eaedf4",
  lightShade: "#c9cfdf",
  dark: defaultColors.dark,
  darkShade: "#3f527d",
  border: "#b6bed2",
  shadow: rgba(defaultColors.dark, 0.225),
  warning: "#F2C94C",
  success: "#49BF5C",
  info: "#1665D8",

  modes: {
    dark: {
      primary: "#E52B38", //main color cta buttons
      secondary: "#b94284", // used for :hover in some place...
      active: "#e5842b", //Active color for links and so on
      bg: defaultColors.white,
      heading: defaultColors.dark,
      text: "#3f527d",
      light: "#eaedf4",
      lightShade: "#c9cfdf",
      dark: defaultColors.dark,
      darkShade: "#3f527d",
      border: "#b6bed2",
      shadow: rgba(defaultColors.dark, 0.225),
      warning: "#F2C94C",
      success: "#49BF5C",
      info: "#1665D8",
    },
  },
}

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
}

export default theme
