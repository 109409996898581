import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  color,
  background,
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from "styled-system"

import Span from "./Span"

const BadgeSolid = styled.span`
  font-size: 12px;
  font-weight: 600;
  /* letter-spacing: -0.41px; */
  border-radius: 20px;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`

const Badge = ({
  link,
  color = "light",
  bg = "primary",
  children,
  ...rest
}) => {
  return (
    <BadgeSolid
      color={color}
      border={`1px solid`}
      borderColor={bg}
      bg={bg}
      {...rest}
    >
      {link ? (
        <Link
          to={link}
          css={`
            &:focus,
            &:hover,
            &:active,
            &:visited {
              text-decoration: none !important;
            }
          `}
        >
          <Span color="light">{children}</Span>
        </Link>
      ) : (
        children
      )}
    </BadgeSolid>
  )
}

export default Badge
