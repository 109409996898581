import React from "react"
import { Button as BsButton } from "react-bootstrap"
import { darken, lighten, rgba } from "polished"
import styled from "styled-components"
import {
  color,
  background,
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from "styled-system"

const ButtonStyled = styled(BsButton)`
  font-family: Open Sans;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
  transform: translateY(0);
  transition: all 0.3s ease-out;
  border-radius: ${({ borderradius }) => borderradius};

  &:hover {
    color: ${({ theme, color }) => theme.colors[color]} !important;
    ${border};
    border-color: transparent !important;
    background-color: ${({ theme, bg }) =>
      darken(0.05, theme.colors[bg])} !important;
    transform: translateY(-5px);
    box-shadow: ${({ theme }) =>
      `0 4px 10px 0 ${rgba(theme.colors.dark, 0.25)}`} !important;
  }

  &:focus,
  &:active {
    color: ${({ theme, color }) => theme.colors[color]} !important;

    background-color: ${({ theme, bg }) =>
      darken(0.05, theme.colors[bg])} !important;

    border-color: ${({ theme, bordercolor }) =>
      `${lighten(0.05, theme.colors[bordercolor])}`} !important;

    box-shadow: ${({ theme, bg }) =>
      `0 0 0 0.2rem ${rgba(theme.colors[bg], 0.25)}, 0 6px 10px 0 ${rgba(
        theme.colors.dark,
        0.275
      )}`} !important;
  }
`

const Button = ({ color = "light", size = "", bg = "primary", ...rest }) => {
  return (
    <ButtonStyled
      color={color}
      border={`1px solid`}
      bordercolor={bg}
      bg={bg}
      size={size}
      py={size === "huge" ? "14px" : null}
      px={size === "huge" ? "24px" : null}
      borderradius={size === "huge" ? "8px" : "4px"}
      {...rest}
    />
  )
}

export default Button
