import { allLangs } from "../utils/configClient"

const getLangSlug = lang =>
  lang === "" || lang === allLangs.default ? "" : `/${lang}`

export const slugHome = (lang = "") => {
  return lang === "" || lang === allLangs.default ? "/" : `/${lang}`
}

export const slugAbout = (lang = "") => {
  return `${getLangSlug(lang)}/about`
}

export const slugApplications = (lang = "") => {
  return `${getLangSlug(lang)}/applications`
}

export const slugCareer = (lang = "") => {
  return `${getLangSlug(lang)}/career`
}

export const slugDisclaimer = (lang = "") => {
  return `${getLangSlug(lang)}/disclaimer`
}

export const slugProductDatabase = (lang = "") => {
  return `${getLangSlug(lang)}/product-database`
}

export const slugHonors = (lang = "") => {
  return `${getLangSlug(lang)}/honors`
}

export const slugTechnicalOverview = (lang = "") => {
  return `${getLangSlug(lang)}/technical-overview`
}

export const slugCat = (catSlug, lang = "") => {
  return `${getLangSlug(lang)}/cat/${catSlug}`
}

export const slugCatProducts = (catSlug, lang = "") => {
  return `${getLangSlug(lang)}/cat-products/${catSlug}`
}

export const slugSubCat = (subCatSlug, catSlug, lang = "") => {
  return `${getLangSlug(lang)}/cat/${catSlug}/sub-cat/${subCatSlug}`
}

export const slugProd = (prodSlug, subCatSlug, catSlug, lang = "") => {
  return `${getLangSlug(
    lang
  )}/cat/${catSlug}/sub-cat/${subCatSlug}/pro/${prodSlug}`
}

export const slugNews = (newsSlug, lang = "") => {
  return `${getLangSlug(lang)}/news/${newsSlug}`
}

export const slugMap = (mapSlug, lang = "") => {
  return `${getLangSlug(lang)}/map/${mapSlug}`
}
export const slugHonor = (honorSlug, lang = "") => {
  return `${getLangSlug(lang)}/honor/${honorSlug}`
}
