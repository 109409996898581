import React, { useState, useContext } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import {
  Modal,
  Container,
  InputGroup,
  FormControl,
  Button,
  Card,
} from "react-bootstrap"

import GlobalContext from "../../contexts/GlobalContext"
import { theme } from "../../utils"
import { slugCat, slugSubCat, slugProd } from "../../utils/slugs"
import { itemsByLang } from "../../utils/helperFn"

const ModalStyled = styled(Modal)`
  &.modal {
    padding-left: 0 !important;
  }
  .modal-dialog {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
  }
  .modal-content {
    min-width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 0;
    padding-top: 15px;
    @media only screen and (min-width: ${theme.breakpoints.lg}px) {
      padding-top: 30px;
    }
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(7px);
      z-index: -1;
    }
  }
`

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 2rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: ${theme.dark};
  @media only screen and (min-width: ${theme.breakpoints.md}px) {
    right: 3rem;
  }
`

const CloseButton = props => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: #ffffff;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="#ffffff"
      ></path>
    </svg>
  </CloseWrapper>
)

const Search = props => {
  const gContext = useContext(GlobalContext)

  const {
    allPrismicCommon,
    allPrismicCategory,
    allPrismicSubCategory,
    allPrismicProduct,
  } = useStaticQuery(graphql`
    {
      allPrismicCommon {
        nodes {
          lang
          data {
            search_placeholder_text {
              text
            }
            search_button_text {
              text
            }
            pre_search_text {
              text
            }
            search_result_title {
              text
            }
            search_error_text {
              text
            }
            result_category_label {
              text
            }
            result_sub_category_label {
              text
            }
          }
        }
      }
      allPrismicCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
          }
          tags
        }
      }
      allPrismicSubCategory(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            title {
              text
            }
            category {
              uid
            }
          }
          tags
        }
      }
      allPrismicProduct(sort: { fields: data___order, order: ASC }) {
        nodes {
          uid
          lang
          data {
            type
            title {
              text
            }
            category {
              uid
            }
            sub_category {
              uid
            }
          }
          tags
        }
      }
    }
  `)

  // Set posts from query

  const cats = itemsByLang(allPrismicCategory.nodes, gContext.currentLang)
  const subCats = itemsByLang(allPrismicSubCategory.nodes, gContext.currentLang)
  const products = itemsByLang(allPrismicProduct.nodes, gContext.currentLang)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const pData = prismicCommon?.data

  const emptyQuery = ""
  const [state, setState] = useState({
    filteredCats: [],
    filteredSubCats: [],
    filteredProducts: [],
    query: emptyQuery,
    showResult: false,
  })

  // code-changes-start
  const handleInputChange = event => {
    const query = event.target.value

    setState({
      ...state,
      showResult: false,
      query, // with current query string from the `Input` event
    })
  }

  const filterByQuery = (query, items, type = "cat") => {
    const filteredData = items.filter(item => {
      const title = item.data.title ? item.data.title.text : ""
      const tags = item.tags

      return (
        // standardize data with .toLowerCase()
        // return true if the title or tags
        // contains the query string

        title.toLowerCase().includes(query.toLowerCase()) ||
        tags.findIndex(item => query.toLowerCase() === item.toLowerCase()) >= 0
      )
    })

    if (type === "subcat") {
      return filteredData.filter(item => item.data.category.uid !== null)
    }
    if (type === "prod") {
      return filteredData.filter(
        item =>
          item.data.category.uid !== null && item.data.sub_category.uid !== null
      )
    } else {
      return filteredData
    }
  }

  const handleSearch = e => {
    e.preventDefault()
    const query = state.query

    if (query.length > 0) {
      // return all filtered posts
      const filteredCats = filterByQuery(query, cats)
      const filteredSubCats = filterByQuery(query, subCats, "subcat")
      const filteredProducts = filterByQuery(query, products, "prod")
      // update state according to the latest query and results
      setState({
        ...state,
        showResult: true,
        filteredCats,
        filteredSubCats,
        filteredProducts,
      })
    }
  }

  const handleInputClear = () => {
    setState({
      ...state,
      query: "",
      showResult: false,
      filteredCats: [],
      filteredSubCats: [],
      filteredProducts: [],
    })
  }

  const navigateTo = (e, link) => {
    e.preventDefault()
    gContext.toggleSearch()

    navigate(link)
  }

  const resultNull =
    state.filteredCats.length <= 0 &&
    state.filteredSubCats.length <= 0 &&
    state.filteredProducts.length <= 0

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.searchVisible}
      onHide={gContext.toggleSearch}
    >
      <Modal.Body className="text-center position-relative">
        <CloseButton onClick={gContext.toggleSearch} />
        <Container>
          <div
            className="mt-4 mx-auto d-flex flex-column justify-content-center"
            css={`
              width: 500px;
              max-width: 95%;
            `}
          >
            <form onSubmit={handleSearch}>
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  placeholder={
                    pData?.search_placeholder_text?.text || "Type here"
                  }
                  value={state.query}
                  onChange={handleInputChange}
                  size="lg"
                  autoFocus
                />
                <InputGroup.Append>
                  <Button variant="dark" onClick={handleInputClear}>
                    <IoMdClose
                      css={`
                        font-size: 1.25rem;
                      `}
                    />
                  </Button>
                  <Button variant="primary" type="submit">
                    <FaSearch className="mr-2" />{" "}
                    {pData?.search_button_text?.text || "Search"}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </form>
            <div>
              {state.showResult ? (
                <>
                  <h3 className="my-4 text-white">
                    {pData?.search_result_title?.text || "Search Results"}
                  </h3>
                  {resultNull ? (
                    <h5 className="text-center text-warning">
                      {pData?.search_error_text?.text ||
                        "No match! Pls try again."}
                    </h5>
                  ) : (
                    <div className="text-left">
                      {state.filteredCats.length > 0 &&
                        state.filteredCats.map((cat, index) => (
                          <Card
                            as="a"
                            href={slugCat(cat.uid, gContext.currentLang)}
                            key={index}
                            className="mb-3 bg-white shadow"
                            onClick={e =>
                              navigateTo(
                                e,
                                slugCat(cat.uid, gContext.currentLang)
                              )
                            }
                          >
                            <Card.Body>
                              {cat.data.title && (
                                <h5
                                  className="text-primary mb-2"
                                  css={`
                                    font-size: 1rem !important;
                                  `}
                                >
                                  {cat.data.title.text}
                                </h5>
                              )}

                              <Card.Subtitle className="text-muted">
                                <small>
                                  {pData?.result_category_label?.text ||
                                    "Category"}
                                </small>
                              </Card.Subtitle>
                            </Card.Body>
                          </Card>
                        ))}

                      {state.filteredSubCats.length > 0 &&
                        state.filteredSubCats.map((subCat, index) => (
                          <Card
                            as="a"
                            href={slugSubCat(
                              subCat.uid,
                              subCat.data.category.uid,
                              gContext.currentLang
                            )}
                            key={index}
                            className="mb-3 bg-white shadow"
                            onClick={e =>
                              navigateTo(
                                e,
                                slugSubCat(
                                  subCat.uid,
                                  subCat.data.category.uid,
                                  gContext.currentLang
                                )
                              )
                            }
                          >
                            <Card.Body>
                              {subCat.data.title && (
                                <h5
                                  className="text-primary mb-2"
                                  css={`
                                    font-size: 1rem !important;
                                  `}
                                >
                                  {subCat.data.title.text}
                                </h5>
                              )}

                              <Card.Subtitle className="text-muted">
                                <small>
                                  {pData?.result_sub_category_label?.text ||
                                    "Sub Category"}
                                </small>
                              </Card.Subtitle>
                            </Card.Body>
                          </Card>
                        ))}

                      {state.filteredProducts.length > 0 &&
                        state.filteredProducts.map((product, index) => (
                          <Card
                            as="a"
                            href={slugProd(
                              product.uid,
                              product.data.sub_category.uid,
                              product.data.category.uid,
                              gContext.currentLang
                            )}
                            key={index}
                            className="mb-3 bg-white shadow"
                            onClick={e =>
                              navigateTo(
                                e,
                                slugProd(
                                  product.uid,
                                  product.data.sub_category.uid,
                                  product.data.category.uid,
                                  gContext.currentLang
                                )
                              )
                            }
                          >
                            <Card.Body>
                              {product.data.title && (
                                <h5
                                  className="text-primary mb-2"
                                  css={`
                                    font-size: 1rem !important;
                                  `}
                                >
                                  {product.data.title.text}
                                </h5>
                              )}

                              <Card.Subtitle className="text-muted">
                                <small>{product.data.type}</small>
                              </Card.Subtitle>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  )}
                </>
              ) : (
                <h5 className="text-center text-white">
                  {pData?.pre_search_text?.text || "Enter your search"}
                </h5>
              )}
            </div>
          </div>
        </Container>
      </Modal.Body>
    </ModalStyled>
  )
}

export default Search
