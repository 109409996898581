import { allLangs } from "./configClient"

export const titleCase = str => {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}

export const random = items => {
  const rand = Math.floor(Math.random() * items.length)
  return items[rand]
}

export const itemsByLang = (items = [], lang = allLangs.default) => {
  return items.filter(item => item.lang === lang)
}
