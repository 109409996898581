import React, { useContext } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container, Button } from "react-bootstrap"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"

import Logo from "../Logo"
import Menu from "../Menu"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"
import { slugHome } from "../../utils/slugs"

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-out;
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }
`

const Drawer = styled.div`
  position: fixed;
  width: 18rem;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 99999;
  background: ${({ theme }) => theme.colors.lightShade} !important;
  overflow-y: auto;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  &.hidden {
    transform: translateX(100%);
  }
  .dropdown-menu {
    position: static !important;
    will-change: initial !important;
    transform: initial !important;
    float: none !important;
  }
`

const LogoContainer = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`

const Offcanvas = ({ ...rest }) => {
  const { allPrismicCommon } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            menu_search {
              text
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const { menu_search } = prismicCommon?.data

  if (typeof document !== "undefined") {
    if (gContext.visibleOffCanvas) {
      document.querySelector("html").classList.add("has-offcanvas")
      document.body.classList.add("has-offcanvas")
    } else {
      document.querySelector("html").classList.remove("has-offcanvas")
      document.body.classList.remove("has-offcanvas")
    }
  }

  return (
    <div {...rest}>
      <Overlay
        className={gContext.visibleOffCanvas ? "" : "hidden"}
        onClick={gContext.closeOffCanvas}
      />
      <Drawer
        className={`bg-dark ${gContext.visibleOffCanvas ? "" : "hidden"}`}
      >
        <Container>
          <div className="p-3">
            <LogoContainer className="my-3">
              <Link
                to={slugHome(gContext.currentLang)}
                onClick={gContext.closeOffCanvas}
              >
                <Logo />
              </Link>
            </LogoContainer>
            <Menu
              className="mt-4 flex-column navbar-nav"
              onChangeLink={gContext.closeOffCanvas}
              vertical
            />
            <div className="d-flex align-items-center justify-content-start mt-3">
              <Button
                variant="outline-dark"
                onClick={() => {
                  gContext.toggleSearch()
                  gContext.closeOffCanvas()
                }}
                className="bg-transparent shadow-none border-0 text-dark pl-0 font-weight-bolder"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <FaSearch className="mr-2" />{" "}
                  {menu_search ? menu_search.text : "Search"}
                </span>
              </Button>
            </div>
          </div>
        </Container>
      </Drawer>
    </div>
  )
}

export default Offcanvas
