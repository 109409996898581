import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import logo from "../../assets/images/logo.png"

const Logo = ({ footer = false, alt, height, className = "", ...rest }) => {
  const { prismicCommon } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          logo_header {
            url
          }
          logo_footer {
            url
          }
        }
      }
    }
  `)

  const logo_header = prismicCommon?.data.logo_header
    ? prismicCommon?.data.logo_header.url
    : logo

  const logo_footer = prismicCommon?.data.logo_footer
    ? prismicCommon?.data.logo_footer.url
    : logo

  return (
    <>
      <div>
        <img
          src={footer ? logo_footer : logo_header}
          className="img-fluid"
          css={`
            width: 100px;
          `}
          alt=""
        />
      </div>
    </>
  )
}

export default Logo
