import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Nav } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"

import GlobalContext from "../../contexts/GlobalContext"
import { itemsByLang } from "../../utils/helperFn"
import { slugHome } from "../../utils/slugs"
import { theme } from "../../utils"

const NavStyled = styled(Nav)`
  .nav-item {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
    a {
      font-weight: 600;
      position: relative;
      &:hover,
      &:active,
      &:focus,
      &.active {
        text-decoration: none;
        outline: none !important;
        color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
`

const Menu = ({
  vertical = false,
  isPinned = false,
  onChangeLink = () => {},
  header = false,
  ...rest
}) => {
  const { pathname } = useLocation()
  const gContext = useContext(GlobalContext)

  const { allPrismicCommon } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            menu_home {
              text
            }
            menu_about {
              text
            }
            menu_products {
              text
            }
            menu_news {
              text
            }
            menu_sales_contact {
              text
            }
            menu_partners {
              text
            }
            menu_support {
              text
            }
            menu_contact {
              text
            }
            menu_search {
              text
            }
          }
        }
      }
    }
  `)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const {
    menu_home,
    menu_about,
    menu_products,
    menu_news,
    menu_partners,
    menu_sales_contact,
    menu_support,
    menu_contact,
    menu_search,
  } = prismicCommon?.data

  const menuItemsNavHome = [
    {
      title: menu_home ? menu_home.text : "Home",
      link: "hero",
      isFixed: false,
    },
    {
      title: menu_about ? menu_about.text : "About",
      link: "about",
      isFixed: false,
    },
    {
      title: menu_products ? menu_products.text : "Products",
      link: "products",
      isFixed: false,
    },
    {
      title: menu_news ? menu_news.text : "News",
      link: "news",
      isFixed: false,
    },
    {
      title: menu_partners ? menu_partners.text : "Partners",
      link: "partners",
      isFixed: false,
    },
    {
      title: menu_sales_contact ? menu_sales_contact.text : "Sales Contact",
      link: "representatives",
      isFixed: false,
    },
    {
      title: menu_support ? menu_support.text : "Support",
      link: "support",
      isFixed: true,
    },
    {
      title: menu_contact ? menu_contact.text : "Contact",
      link: "contact",
      isFixed: true,
    },
  ]

  const menuItems = menuItemsNavHome

  return (
    <NavStyled {...rest}>
      {menuItems.map((item, index) => (
        <Nav.Item key={index} className={`nav-item ${vertical && `py-2`}`}>
          {item.isFixed ? (
            <AnchorLink
              to={`${pathname}#${item.link}`}
              title={item.title}
              className={isPinned ? "text-dark" : "text-dark"}
              stripHash
            />
          ) : (
            <AnchorLink
              to={`${slugHome(gContext.currentLang)}#${item.link}`}
              title={item.title}
              className={isPinned ? "text-dark" : "text-dark"}
              stripHash
            />
          )}
        </Nav.Item>
      ))}
    </NavStyled>
  )
}

export default Menu
