import React from "react"
import styled from "styled-components"
import { color, space, typography, shadow } from "styled-system"

const Paragraph = styled.div`
  font-family: Open Sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.75rem;
  letter-spacing: -0.56px;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`
const ParagraphSmall = styled(Paragraph)`
  font-size: 14px;
  line-height: 22px;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphTag = styled(Paragraph)`
  font-size: 12px;
  line-height: 18px;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Text = ({ variant, ...props }) => {
  let TextRender

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall
      break
    case "tag":
      TextRender = ParagraphTag
      break
    default:
      TextRender = Paragraph
  }

  return <TextRender color="text" {...props} />
}

export default Text
