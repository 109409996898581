import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import { Container, Navbar, Button } from "react-bootstrap"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { FaSearch } from "react-icons/fa"
import { useLocation } from "@reach/router"

import { breakpoints } from "../../utils"
import { useWindowSize } from "../../hooks"
import { slugHome } from "../../utils/slugs"
import GlobalContext from "../../contexts/GlobalContext"
import { allLangs } from "../../utils/configClient"
import { itemsByLang } from "../../utils/helperFn"

import Logo from "../Logo"
import Offcanvas from "../Offcanvas"
import Menu from "../Menu"
import NewsTicker from "../NewsTicker"

const NavbarStyled = styled(Navbar)`
  transition: all 0.3s ease-out;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  background: ${({ theme }) => theme.colors.light} !important;
  z-index: 999;
  &.scrolling {
    /* background: rgba(47, 72, 88, 0.7) !important; */
  }
  .navbar-brand {
    font-weight: 600;
  }

  .switch-box {
    margin-left: 36px;
  }
  .btn-local {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 13px;
    padding: 0 0.125rem;
    &.active {
      color: red !important;
    }
  }
`

const TickerContainer = styled.div`
  transition: all 0.3s ease-out;
  height: 60px;
  &.scrolling {
    height: 0;
  }
`

const NavbarTop = () => {
  const { allPrismicCommon, allPrismicTicker } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            menu_search {
              text
            }
          }
        }
      }
      allPrismicTicker {
        nodes {
          lang
          data {
            page_type
            ref_category {
              uid
            }
            title {
              text
            }
            title_bg
            title_color
            content_bg
            text_color
            sub_text_color
            ticker_items {
              ticker_item_title {
                text
              }
              ticker_item {
                document {
                  ... on PrismicNews {
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const gContext = useContext(GlobalContext)

  const allTickersNodes = itemsByLang(
    allPrismicTicker.nodes,
    gContext.currentLang
  )

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const { menu_search } = prismicCommon?.data

  const [isPinned, setIsPinned] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [showScrolling, setShowScrolling] = useState(false)
  const size = useWindowSize()

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true)
    } else {
      setShowScrolling(false)
    }
  })

  useEffect(() => {
    if (size.width < breakpoints.lg) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [size])

  const location = useLocation()

  const handleLang = lang => {
    const prevPathname = location.pathname

    if (lang !== gContext.currentLang) {
      if (
        prevPathname === "/zh-hk/" ||
        prevPathname === "/zh-hk" ||
        prevPathname === "/zh-cn/" ||
        prevPathname === "/zh-cn"
      ) {
        const link = lang === allLangs.default ? "/" : `/${lang}`
        gContext.setCurrentLang(lang)

        setTimeout(() => {
          navigate(link)
        }, 10)
      } else {
        const re = RegExp(`/${gContext.currentLang}`, "g")

        const pathname = prevPathname.replace(re, "")
        const link =
          lang === allLangs.default ? pathname : `/${lang}${pathname}`
        gContext.setCurrentLang(lang)

        setTimeout(() => {
          navigate(link)
        }, 10)
      }
    }
  }

  return (
    <>
      {isMobile && <Offcanvas className="border-right" />}
      <TickerContainer
        className={`${
          showScrolling || allTickersNodes.length <= 0 ? "scrolling" : ""
        }`}
      >
        <NewsTicker allTickersNodes={allTickersNodes} />
      </TickerContainer>

      <NavbarStyled
        className={`px-md-0 ${gContext.header.bgClass} ${
          showScrolling ? "scrolling" : ""
        }`}
      >
        <Container className="px-sm-3">
          <Navbar.Brand as={Link} to={slugHome(gContext.currentLang)}>
            <Logo />
          </Navbar.Brand>

          {isMobile ? (
            <>
              <div className="switch-box ml-0">
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.en ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.en)}
                >
                  EN
                </button>{" "}
                {" / "}
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.hk ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.hk)}
                >
                  繁
                </button>
                {" / "}
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.cn ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.cn)}
                >
                  简
                </button>
              </div>
              <div
                css={`
                  font-weight: 600;
                `}
              >
                <div
                  role="button"
                  className="d-flex"
                  css={`
                    cursor: pointer;
                  `}
                  onClick={() => gContext.toggleOffCanvas()}
                  onKeyDown={() => gContext.toggleOffCanvas()}
                  tabIndex={-1}
                >
                  <svg
                    viewBox="0 0 24 24"
                    css={`
                      height: 1.5rem;
                    `}
                    className="ml-3 text-dark"
                  >
                    <path
                      d="M0 17h16v2H0zm0-6h16v2H0zm0-6h24v2H0z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-grow-1">
                <Menu
                  className="my-3 ml-auto d-flex align-items-center"
                  header
                  isPinned={isPinned}
                />
                <div className="d-flex align-items-center ml-lg-3">
                  <Button
                    variant="outline-dark"
                    block
                    onClick={() => {
                      gContext.toggleSearch()
                      gContext.closeOffCanvas()
                    }}
                    className="bg-transparent shadow-none border-0 text-dark font-weight-bolder"
                  >
                    <span className="d-flex align-items-center justify-content-center">
                      <FaSearch className="mr-2" />
                      {menu_search ? menu_search.text : "Search"}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="switch-box">
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.en ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.en)}
                >
                  EN
                </button>{" "}
                {" / "}
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.hk ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.hk)}
                >
                  繁
                </button>
                {" / "}
                <button
                  className={`btn-local ${
                    gContext.currentLang === allLangs.cn ? "active" : ""
                  }`}
                  onClick={() => handleLang(allLangs.cn)}
                >
                  简
                </button>
              </div>
            </>
          )}
        </Container>
      </NavbarStyled>
    </>
  )
}

export default NavbarTop
