import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { FaAngleDoubleUp } from "react-icons/fa"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import AOS from "aos"

// import Loader from "../Loader"
import Header from "../Header"
import Footer from "../Footer"
import Search from "../Search"
import Lightbox from "../Lightbox"
import GlobalContext from "../../contexts/GlobalContext"
import GlobalStyle from "../../utils/globalStyle"

import "./bootstrap-custom.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "aos/dist/aos.css"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import { get, merge } from "lodash"

// the full theme object
import { theme as baseTheme } from "../../utils"
import { itemsByLang } from "../../utils/helperFn"

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 0.75s ease-in 0.25s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`

// options for different color modes
const modes = { light: "light", dark: "dark" }

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  })

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext)

  const [visibleLoader, setVisibleLoader] = useState(true)

  useLayoutEffect(() => {
    setVisibleLoader(false)
    AOS.init({ once: true })
  }, [])

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.

        gContext.closeOffCanvas()
      },
      false
    )
    window.addEventListener(
      "pushState",
      function (event) {
        gContext.closeOffCanvas()
      },
      false
    )
  }, [gContext])

  const { allPrismicCommon } = useStaticQuery(graphql`
    query {
      allPrismicCommon {
        nodes {
          lang
          data {
            color_primary
            color_hover
            color_active
            color_heading
            color_text

            meta_title {
              text
            }
            meta_description {
              text
            }
          }
        }
      }
    }
  `)

  const prismicCommon = itemsByLang(
    allPrismicCommon.nodes,
    gContext.currentLang
  )[0]

  const {
    color_primary,
    color_hover,
    color_active,
    color_heading,
    color_text,
    meta_title,
    meta_description,
  } = prismicCommon?.data

  const currentTheme = {
    ...getTheme(modes.dark),
    colors: {
      ...getTheme(modes.dark).colors,
      primary: color_primary,
      secondary: color_hover,
      active: color_active,
      heading: color_heading,
      text: color_text,
      dark: color_heading,
      darkShade: color_text,
    },
  }

  const history = useLocation()

  const [showGoToTop, setShowGoToTop] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > -500) {
      setShowGoToTop(false)
    } else {
      setShowGoToTop(true)
    }
  })

  return (
    <>
      <GatsbySeo
        title={meta_title?.text || "Emeigroup"}
        description={
          meta_description?.text ||
          "We are an established electronic materials and components manufacturer specialising in the design, research and development, manufacturing and sales of electronic materials and components."
        }
      />
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <LoaderContainer
          id="loading"
          className={visibleLoader ? "" : "inActive"}
        >
          {/* <Loader /> */}
        </LoaderContainer>
        <div
          className="min-vh-100 d-flex flex-column align-items-stretch"
          id="top"
        >
          <Header className="flex-shrink-0" />

          <div
            className="flex-grow-1"
            css={`
              padding-top: 73px;
            `}
          >
            {children}
          </div>

          <Footer className="flex-shrink-0" />

          <AnchorLink
            to={`${history.pathname}#top`}
            title={<FaAngleDoubleUp fontSize="26px" className="text-primary" />}
            className={`position-fixed d-flex align-items-center border border-primary justify-content-center ${
              showGoToTop ? "visible" : ""
            }`}
            css={`
              width: 50px;
              height: 50px;
              left: 1.5rem;
              bottom: 1.5rem;
              border-width: 3px !important;
              border-radius: 50%;
              background: #fff;

              z-index: 1000;
              cursor: pointer;
              transition: all 0.5s ease-in-out;
              transform: scale(0);
              visibility: hidden;

              &.visible {
                visibility: visible;
                transform: scale(1);
              }
              &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
                transform: scale(1) translateY(-4px) !important;
              }
            `}
            stripHash
          />
        </div>

        <Search />
        <Lightbox />
      </ThemeProvider>
    </>
  )
}

export default Layout
