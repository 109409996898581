import { createGlobalStyle } from "styled-components"
import { rgba } from "polished"

const globalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  html, body {
    background-color: ${({ theme }) => theme.colors.light} !important;
    font-size: 1.0125rem !important;
    color: ${({ theme }) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.05rem !important
  }


  a {
    color: ${({ theme }) => theme.colors.text} !important;
    transition: all 0.15s ease-out;
    user-select: none;
    &:hover, &:active, &:focus, &.active{
      text-decoration: none;
      outline: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
  iframe{
    height: 280px !important;
  }
  .breadcrumb-link {
    color: ${({ theme }) => theme.colors.primary} !important;
    transition: all 0.15s ease-out;
    user-select: none;
    &:hover, &:active, &:focus, &.active{
      text-decoration: none;
      outline: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

    article a , table tr td p a, p a{
    color: ${({ theme }) => theme.colors.secondary} !important;
    transition: all 0.15s ease-out;
    user-select: none;
     text-decoration: underline !important;
    &:hover, &:active, &:focus, &.active{
      text-decoration: underline !important;
      outline: none !important;
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  svg.divider-shape{
    fill: ${({ theme }) => rgba(theme.colors.light, 0.5)} !important;
  }


.list-group-item, .list-group-item.disable-active.active,  .accordion-card,  .accordion-card> .card-header{

    background: ${({ theme }) => theme.colors.lightShade} !important;
    transition: all 0.2s ease-out;
    border-color: ${({ theme }) => theme.colors.border} !important;
    user-select: none;

   
}
.list-group-item, .list-group-item.disable-active.active a{

    color: ${({ theme }) => theme.colors.text} !important;
    &:hover, &:active, &:focus, &.active{
      text-decoration: none;
      outline: none !important;
      color: ${({ theme }) => theme.colors.text} !important;
    }
}

 .accordion-card> .card-header{
    font-weight: 600;
}

.list-group-item:not(.active):hover, .card-body, .accordion-card:not(.active) > .card-header:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    background-color: #ffffff !important;
}

.list-group-item.active,  .accordion-card.active > .card-header {
  color: #fff !important;
  background: ${({ theme }) => theme.colors.primary} !important;
  border-color: ${({ theme }) => theme.colors.border} !important;
}

/* To fix lazyload component width and height */
.lazyload-wrapper{
  width: 100%;
  height: 100%;
}
.list-group-item{
  cursor: pointer;
}

.slick-prev, .slick-next {
  width: auto;
  height: auto;
}

.rich-content{

  ${
    "" /* p.block-img{
    height: 100%;
    & img{
      height: calc(100% - 1.275rem);
      object-fit: cover;
    }
  } */
  }
  .align-center {
    text-align: center;
    display: block;
  }
  .align-left {
    text-align: left;
    display: block;
  }

  .align-right {
    text-align: right;
    display: block;
  }

  .subscript {
    font-size: 13px;
    position: relative;
    bottom: -4px;
    right: -2px;
    letter-spacing: -0.5px;
  }

  .superscript {
    font-size: 13px;
    position: relative;
    top: -4px;
    left: -2px;
    letter-spacing: -0.5px;
  }

  .font-larger {
    font-size: 1.125rem;
  }
  .font-smaller {
    font-size: 0.9rem;
  }

  .color-red {
    color: red;
  }

  .color-green {
    color: green;
  }

  .color-blue {
    color: blue;
  }
  }
`

export default globalStyle
